import { render, staticRenderFns } from "./afterSales.vue?vue&type=template&id=38f2ad59&scoped=true"
import script from "./afterSales.vue?vue&type=script&lang=js"
export * from "./afterSales.vue?vue&type=script&lang=js"
import style0 from "./afterSales.vue?vue&type=style&index=0&id=38f2ad59&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38f2ad59",
  null
  
)

export default component.exports